import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { PeerConnection } from './peer';
import Peer from 'simple-peer';

export type CompleteSignal =
	| 'complete'
	| 'timeout'
	| 'account_restricted'
	| 'missing_salesnav'
	| 'unipile_login_failed';

export type UpdateSignal = 'basic_login_success';

export class SignalingServerHandler {
	private conn: HubConnection;
	private peer: PeerConnection;
	private completeCb: (signal: CompleteSignal) => unknown;
	private updateCb: (signal: UpdateSignal) => unknown;
	constructor(
		url: string,
		options: Peer.Options,
		onUpdate: (signal: UpdateSignal) => unknown,
		onComplete: (signal: CompleteSignal) => unknown,
		stream: any
	) {
		this.conn = new HubConnectionBuilder()
			.withUrl(url, {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets,
			})
			.build();
		this.peer = new PeerConnection(this.conn, options);
		this.completeCb = onComplete;
		this.updateCb = onUpdate;
		if (stream !== undefined) this.peer.setlocalStream(stream);
	}

	public initialize = async () => {
		this.conn.on('message', this.handleMessage);
		this.conn.on('created', this.handleRoomCreated);
		this.conn.on('joined', this.handleRoomJoined);
		this.conn.on('signal', this.handleSignal);
		await this.conn.start();
	};

	public getPeer = () => this.peer;

	public createOrJoinRoom = async (roomName: string) => {
		await this.conn.invoke('CreateOrJoinRoom', roomName);
	};

	public leaveRoom = async (roomName: string) => {
		await this.conn.invoke('LeaveRoom', roomName);
	};

	public sendMessage = async (roomName: string, message: string) => {
		await this.conn.invoke('SendMessage', message, roomName);
	};

	private handleMessage = (message: string) => {
		console.log(message);
		switch (message) {
			// these are messages that indicate end of the login process
			case 'complete':
			case 'timeout':
			case 'account_restricted':
			case 'missing_salesnav':
			case 'unipile_login_failed':
				this.completeCb(message);
				break;
			case 'basic_login_success':
				this.updateCb(message);
				break;
			default:
				this.peer.clientConn?.signal(message);
		}
	};

	private handleRoomCreated = (roomName: string) => {
		console.log('Room Created: ' + roomName);
		this.peer.startInitiator(roomName);
	};

	private handleRoomJoined = (roomName: string) => {
		console.log('Room Joined: ' + roomName);
		this.peer.startPeer(roomName);
	};

	private handleSignal = (payload: string) => {
		console.log('Signal: ' + payload);
		this.peer.clientConn?.signal(payload);
	};
}
